<template>
    <div class="pl-0 vvt-list-lft-wrapper" ref="resizeableSidebar" :style="sidebarStyle">
        <slot></slot>
    </div>
</template>

<script>
import { debounce } from 'lodash';
const LEA_VVT_LIST_SIDEBAR_WIDTH = 'LEA_VVT_LIST_SIDEBAR_WIDTH';
export default {
    name: 'VvtListLeftSidebar',
    data() {
        return {
            observer: null,
            sidebarStyle: { width: this.getSidebarWidth() }
        }
    },
    methods: {
        initSizeObserver() {
            this.$nextTick(() => {
                const handleResize = debounce(entries => {
                    const item = entries[0];
                    const cr = item.contentRect;
                    const width = cr.width;
                    if(width > 0 && width !== '100%') {
                        this.$setStorage(LEA_VVT_LIST_SIDEBAR_WIDTH, Math.trunc(width));
                    }
                }, 400)
                const el = this.$refs.resizeableSidebar;
                const observer = new ResizeObserver(handleResize);
                if (el instanceof Element) {
                    observer.observe(el);
                }
                this.observer = observer;
            })
        },
        getSidebarWidth() {
            let width = '100%';
            if(this.$vuetify.breakpoint.mdAndUp) {
                width = '320px';
            }

            let sidebarWidth = localStorage.getItem(LEA_VVT_LIST_SIDEBAR_WIDTH);
            if (sidebarWidth !== null && sidebarWidth !== undefined && sidebarWidth !== 0 && sidebarWidth !== '0' && sidebarWidth !== '0px') {
                width = sidebarWidth + 'px';
            } 
            return width;
        },
    },
    mounted () {
        this.initSizeObserver();
    },
    beforeDestroy() {
        if (this.observer && this.$refs.resizeableSidebar instanceof Element) {
            this.observer.unobserve(this.$refs.resizeableSidebar)
        }
        
    }
}
</script>
            